<template>
  <div>
    <section
      v-if="!validToken" class="section">
      <div class="container is-desktop">
        <div class="login-form--size has-text-centered">
          <h3 class="title is-5">Reset password link had expired</h3>
          <p class="subitle is-7">Click the link below to request a new link</p>
          <router-link
            v-if="$route.query.isChangePassword"
            class="el-button el-button--text"
            :to="{ name: 'resetPassword', query: $route.query }">
            Request a new link
          </router-link>
          <router-link
            v-else
            class="el-button el-button--text"
            :to="{ name: 'forgotPassword', query: $route.query }">
            Request a new link
          </router-link>
        </div>
      </div>
    </section>
    <section
      v-else class="section">
      <div class="container is-desktop">
        <h3 class="title is-marginless is-5 has-text-centered">New password</h3>
        <div class="password-info has-text-centered">
          <el-alert
            title="You cannot reuse your old password."
            type="info"
            left
            :closable="false"
            class="is-white"
            show-icon>
          </el-alert>

          <p class="has-text-left">Please enter your email below and a password<br>reset link will be sent to you.</p>
          <p class="password-hint">
              Hint: The password should be minimum 8-digit alphanumeric characters. To make it stronger, use upper and lower case letters, numbers and symbols like @ # $ % ^ & + = ! ).
            </p>
        </div>
        <PpGap />
        <div class="shadow-box form-content login-form--email-login">
          <el-form
            label-position="top"
            :model="localForm"
            ref="localForm"
            @submit.native="doSubmit"
            class="">
            <el-form-item
              label="New password"
              prop="password"
              :rules="[
                { min: 8, message: 'Your password is too short.', trigger: [ 'change', 'blur' ] },
                { required: true, message: 'Password is required', trigger: [ 'change', 'blur' ] }
              ]">
              <el-input v-model.trim="localForm.password"
              name="formPassword"
              autocomplete="off"
              type="password"
              @blur="handlePasswordBlur"
              placeholder="xxxxxxxxxx" />
            </el-form-item>
            <el-form-item
              label="Confirm password"
              prop="rePassword"
              :rules="[
                { validator: validatePass, trigger: [ 'change', 'blur' ] },
                { required: true, message: 'Please confirm your new password.', trigger: [ 'change', 'blur' ] }
              ]">
              <el-input v-model.trim="localForm.rePassword"
              name="formConfirmPassword"
              autocomplete="off"
              type="password"
              placeholder="xxxxxxxxxx" />
              <p class="form-help-text">Re-type your new password above.</p>
            </el-form-item>
          <div class="list is-narrow is-gapless">
            <div class="list-content">
            </div>
            <div class="list-action">
              <el-button
                native-type="submit"
                :loading="isLoading"
                type="success">
                Reset Password
              </el-button>
            </div>
          </div>
          </el-form>
        </div>
      </div>
      <PpModal
        v-model="submitModalVisibility"
        :isLoading="isLoading"
        loadingMessage="Requesting">
        <div class="box" style="padding: 0;">
          <div class="box-content"
            v-if="loginError.message">
            <h5 class="is-size-5 has-text-weight-semibold has-text-black-ter">
              <i class="el-icon-warning has-text-danger"/> Reset password error
            </h5>
            <div class="list is-gapless">
              <div class="list-content">
                {{ loginError.message }}
              </div>
            </div>
            <div class="gap-x2"></div>
            <div class="list is-gapless is-bottomless">
              <div class="list-content"></div>
              <div
                class="list-action">
                <el-button
                  type="primary"
                  @click="submitModalVisibility = false">
                  Ok
                </el-button>
              </div>
            </div>
          </div>
          <div class="box-content has-text-centered"
            v-else>
            <PpGap size="large" />
            <i class="el-icon-success has-text-success is-size-1" />
            <div class="gap-x2"></div>
            <h5 class="is-size-5 has-text-weight-semibold has-text-black-ter">
              Successfully reset your password
            </h5>
            <PpGap size="large" />
            <div class="list">
              <div class="list-content"></div>
              <div class="list-action">
                <router-link
                  class="el-button el-button--primary"
                  :to="{ name: 'login', query: $route.query }">
                  Login here
                </router-link>
              </div>
            </div>
          </div>
        </div>
      </PpModal>
      <PpGap size="large" />
    </section>
  </div>
</template>

<script>
import lv2, { axiosErrorHandler } from '@/factories/lv2Api'
import store from '@/store'
import jwt from '../../plugins/jwt'

const postResetPassword = async ({ body }) => {
  try {
    const { data } = await lv2.post(`/users/new-password`, body)
    const { outlets, meta } = data
    store.dispatch('setMeta', meta)
    return outlets
  } catch (err) {
    const parseErr = axiosErrorHandler(err)
    if (parseErr.meta) store.setMetaAction(parseErr.meta)
    if (parseErr && parseErr.type === 'invalid_request_error') throw parseErr
    if (parseErr && parseErr.type === 'authentication_error') throw parseErr
    throw parseErr
  }
}

export default {
  name: 'UserNewPassword',
  props: {
    token: String,
    action: String,
    query: Object
  },
  data () {
    return {
      validToken: true,
      claims: {},
      submitModalVisibility: false,
      isLoading: false,
      loginError: {
      },
      localForm: {
        password: undefined,
        rePassword: undefined
      }
    }
  },
  metaInfo: {
    title: 'New password'
  },
  created () {
    // Prase token
    try {
      this.claims = jwt.decode(this.token)
      if (this.claims.actionCode !== this.action) throw new Error('Action not matched')
      if (Date.now() > this.claims.exp * 1000) throw new Error('Expired')
    } catch (err) {
      this.validToken = false
    }
  },
  methods: {
    handlePasswordBlur () {
      this.$refs.localForm.validateField('rePassword')
    },
    validatePass (rule, value, cb) {
      if (value == null) {
        cb(new Error('Please confirm your new password.'))
      } else if (value !== this.localForm.password) {
        cb(new Error('Both passwords do not matched.'))
      }
      cb()
    },
    doSubmit (e) {
      e.preventDefault()
      this.$refs.localForm.validate((valid) => {
        if (valid) {
          this.submitModalVisibility = true
          this.isLoading = true
          this.loginError = {}
          postResetPassword({ body: { password: this.localForm.password, token: this.token } })
            .then(() => {
              this.isLoading = false
            })
            .catch((err) => {
              this.loginError.message = err.message
              this.isLoading = false
            })
        }
      })
    }
  }
}
</script>

<style lang="scss" scoped>
  @import '../../styles/bulma-variables';
  .login-form--email-login {
    box-shadow: $real-shadow;
  }
  .login-form--size,
  .login-form--email-login {
    margin: auto;
    @include tablet {
      width: 350px;
    }
  }
  .password-info {
    max-width: 350px;
    margin: auto;
  }
  .is-white {
    background: #fff;
  }
  .password-info .el-alert {
    margin: 30px 0 10px 0;
  }
  .password-hint {
    margin: 42px 0 30px 0;
    font-size: 14px;
  }
</style>
