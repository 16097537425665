<template>
  <section class="section">
    <!-- TOKEN INVALID FORMAT or SOMETHING -->
    <div v-if="invalidToken" class="container is-desktop">
      <div class="form--member">
        <i class="el-icon-warning has-text-danger is-size-1 is-pulled-left" />
        <div style="margin-left: 70px;">
          <h5 class="is-size-5 has-text-weight-semibold has-text-black-ter">
            {{ errorMessage }}
          </h5>
          <div class="gap"></div>
          <div class="message is-small">
            <div class="message-body">
              <p class="has-text-dark has-text-weight-semibold">You might want to:</p>
              <ul class="">
                <li>- Request a new invitation from the person in charge</li>
              </ul>
            </div>
          </div>
        </div>
        <div class="gap-x2"></div>
      </div>
    </div>
    <!-- TOKEN EXPIRED -->
    <div v-else-if="expiredToken" class="container is-desktop">
      <div class="form--member">
        <i class="el-icon-warning has-text-danger is-size-1 is-pulled-left" />
        <div style="margin-left: 70px;">
          <h5 class="is-size-5 has-text-weight-semibold has-text-black-ter">
            Sorry! Your invitaion has expired
          </h5>
          <div class="gap"></div>
          <div class="message is-small">
            <div class="message-body">
              <p class="has-text-dark has-text-weight-semibold">You might want to:</p>
              <ul class="">
                <li>- Request a new invitation from the person in charge</li>
              </ul>
            </div>
          </div>
        </div>
        <div class="gap-x2"></div>
      </div>
    </div>
    <!-- NO ERROR -->
    <div v-else class="container is-desktop">
      <div class="form--member" v-if="isSuccess">
        <i class="el-icon-success has-text-success is-size-1 is-pulled-left" />
        <div style="margin-left: 70px;">
          <h5 class="is-size-5 has-text-weight-semibold has-text-black-ter">
            Thank you {{ localForm.displayName }}, your account have been setup successfully.
          </h5>
          <p>Redirectly to homepage</p>
          <div class="gap"></div>
        </div>
      </div>
      <div
        v-if="!isSuccess"
        class="form--member">
        <h4 class="title is-6 has-text-primary">Welcome to RTX Whitelable's portal</h4>
      </div>
      <PpGap />
      <div v-if="!isSuccess"
        class="shadow-box form-content login-form--email-login">
        <PpGap />
        <p class="title is-6"><i class="el-icon-edit has-text-info" /> Complete the form below to proceed.</p>
        <el-form
          label-position="top"
          :model="localForm"
          ref="localForm"
          :status-icon="true"
          @submit.native="doLogin"
          class="">
          <el-form-item
            label="How you want us to address you?"
            prop="displayName"
            :rules="[
              { required: true, message: 'This is required', trigger: [ 'change', 'blur' ] }
            ]">
            <el-input v-model.trim="localForm.displayName"
              name="formDisplayName"
              type="text"
              autocomplete="given-name"
              placeholder="E.g: Alice" />
          </el-form-item>
          <div class="list is-gapless is-narrow has-border-top">

          </div>
          <el-form-item
            label="Login email"
            prop="email"
            :rules="[
              { type: 'email', message: 'Invalid email format', trigger: [ 'change', 'blur', 'input' ] },
              { required: true, message: 'Email is required', trigger: [ 'change', 'blur' ] }
            ]">
            <el-input
              disabled
              :readonly="true"
              v-model.trim="localForm.email"
              name="formEmail"
              type="email"
              autocomplete="email"
              placeholder="E.g: yourname@gmail.com" />
          </el-form-item>
          <el-form-item
            label="Login password (Min 8 characters)"
            prop="password"
            :rules="[
              { min: 8, message: 'Your password is too short.', trigger: [ 'change', 'blur' ] },
              { required: true, message: 'Password is required', trigger: [ 'change', 'blur' ] }
            ]">
            <el-input v-model.trim="localForm.password"
            autocomplete="off"
              name="formPassword"
              type="password"
              placeholder="xxxxxxxxx" />
          </el-form-item>
          <p class="password-hint">
            Hint: The password should be minimum 8-digit alphanumeric characters. To make it stronger, use upper and lower case letters, numbers and symbols like @ # $ % ^ & + = ! ).
          </p>
        <div class="list is-narrow is-gapless">
          <div class="list-content has-line-height-1-125">
          </div>
          <div class="list-action">
            <el-button
              :disabled="isLoading"
              native-type="submit"
              :loading="isLoading"
              type="success">
              Continue
            </el-button>
          </div>
        </div>
        </el-form>
      </div>
      <PpGap />
    </div>
  </section>
</template>

<script>
import jwt from '../../plugins/jwt'
import { usersNew } from '../../factories/manage'

export default {
  name: 'NewUser',
  props: {
    token: String,
    action: String
  },
  data () {
    return {
      expiredToken: false,
      isLoading: false,
      isSuccess: false,
      invalidToken: false,
      errorMessage: '',
      error: null,
      localForm: {
        email: undefined,
        password: undefined
      }
    }
  },
  created () {
    try {
      const { exp, email } = jwt.decode(this.token)
      this.localForm.email = email
      this.localForm.token = this.token
      this.expiredToken = Date.now() > exp * 1000
    } catch (err) {
      this.invalidToken = true
      this.errorMessage = err.message
    }
  },
  metaInfo: {
    title: 'Signup'
  },
  methods: {
    doLogin (e) {
      e.preventDefault()
      this.$refs.localForm.validate((valid) => {
        if (valid) {
          this.isLoading = true
          this.isSuccess = false
          this.error = null
          usersNew(this.localForm)
            .then(({ usrToken }) => {
              if (usrToken == null) throw new Error('Signup success but not receiving cusToken')
              this.isSuccess = true
              // If url has redirect hints
              return setTimeout(() => {
                return this.$router.replace({ name: 'home' })
              }, 2000)
            }).catch((err) => {
              console.log(err)
              this.error = err.message
              this.isLoading = false
            })
        }
      })
    }
  }
}
</script>

<style lang="scss" scoped>
  @import '../../styles/bulma-variables';
  .login-form--social {
    text-align: center;
    & /deep/ .login--fb-button {
      max-width: 280px;
    }
  }
  .login-form--email-login {
    box-shadow: $real-shadow;
  }
  .form--member,
  .login-form--social,
  .login-form--email-login,
  .login-from--social {
    margin: auto;
    @include tablet {
      width: 350px;
    }
  }
  .form-input-password {
    margin-bottom: 42px;
  }
  .password-hint {
    font-size: 14px;
    margin-bottom: 30px;
  }
</style>
