var render = function render(){var _vm=this,_c=_vm._self._c;return _c('section',{staticClass:"section"},[(_vm.invalidToken)?_c('div',{staticClass:"container is-desktop"},[_c('div',{staticClass:"form--member"},[_c('i',{staticClass:"el-icon-warning has-text-danger is-size-1 is-pulled-left"}),_c('div',{staticStyle:{"margin-left":"70px"}},[_c('h5',{staticClass:"is-size-5 has-text-weight-semibold has-text-black-ter"},[_vm._v(" "+_vm._s(_vm.errorMessage)+" ")]),_c('div',{staticClass:"gap"}),_vm._m(0)]),_c('div',{staticClass:"gap-x2"})])]):(_vm.expiredToken)?_c('div',{staticClass:"container is-desktop"},[_vm._m(1)]):_c('div',{staticClass:"container is-desktop"},[(_vm.isSuccess)?_c('div',{staticClass:"form--member"},[_c('i',{staticClass:"el-icon-success has-text-success is-size-1 is-pulled-left"}),_c('div',{staticStyle:{"margin-left":"70px"}},[_c('h5',{staticClass:"is-size-5 has-text-weight-semibold has-text-black-ter"},[_vm._v(" Thank you "+_vm._s(_vm.localForm.displayName)+", your account have been setup successfully. ")]),_c('p',[_vm._v("Redirectly to homepage")]),_c('div',{staticClass:"gap"})])]):_vm._e(),(!_vm.isSuccess)?_c('div',{staticClass:"form--member"},[_c('h4',{staticClass:"title is-6 has-text-primary"},[_vm._v("Welcome to RTX Whitelable's portal")])]):_vm._e(),_c('PpGap'),(!_vm.isSuccess)?_c('div',{staticClass:"shadow-box form-content login-form--email-login"},[_c('PpGap'),_vm._m(2),_c('el-form',{ref:"localForm",attrs:{"label-position":"top","model":_vm.localForm,"status-icon":true},nativeOn:{"submit":function($event){return _vm.doLogin.apply(null, arguments)}}},[_c('el-form-item',{attrs:{"label":"How you want us to address you?","prop":"displayName","rules":[
            { required: true, message: 'This is required', trigger: [ 'change', 'blur' ] }
          ]}},[_c('el-input',{attrs:{"name":"formDisplayName","type":"text","autocomplete":"given-name","placeholder":"E.g: Alice"},model:{value:(_vm.localForm.displayName),callback:function ($$v) {_vm.$set(_vm.localForm, "displayName", (typeof $$v === 'string'? $$v.trim(): $$v))},expression:"localForm.displayName"}})],1),_c('div',{staticClass:"list is-gapless is-narrow has-border-top"}),_c('el-form-item',{attrs:{"label":"Login email","prop":"email","rules":[
            { type: 'email', message: 'Invalid email format', trigger: [ 'change', 'blur', 'input' ] },
            { required: true, message: 'Email is required', trigger: [ 'change', 'blur' ] }
          ]}},[_c('el-input',{attrs:{"disabled":"","readonly":true,"name":"formEmail","type":"email","autocomplete":"email","placeholder":"E.g: yourname@gmail.com"},model:{value:(_vm.localForm.email),callback:function ($$v) {_vm.$set(_vm.localForm, "email", (typeof $$v === 'string'? $$v.trim(): $$v))},expression:"localForm.email"}})],1),_c('el-form-item',{attrs:{"label":"Login password (Min 8 characters)","prop":"password","rules":[
            { min: 8, message: 'Your password is too short.', trigger: [ 'change', 'blur' ] },
            { required: true, message: 'Password is required', trigger: [ 'change', 'blur' ] }
          ]}},[_c('el-input',{attrs:{"autocomplete":"off","name":"formPassword","type":"password","placeholder":"xxxxxxxxx"},model:{value:(_vm.localForm.password),callback:function ($$v) {_vm.$set(_vm.localForm, "password", (typeof $$v === 'string'? $$v.trim(): $$v))},expression:"localForm.password"}})],1),_c('p',{staticClass:"password-hint"},[_vm._v(" Hint: The password should be minimum 8-digit alphanumeric characters. To make it stronger, use upper and lower case letters, numbers and symbols like @ # $ % ^ & + = ! ). ")]),_c('div',{staticClass:"list is-narrow is-gapless"},[_c('div',{staticClass:"list-content has-line-height-1-125"}),_c('div',{staticClass:"list-action"},[_c('el-button',{attrs:{"disabled":_vm.isLoading,"native-type":"submit","loading":_vm.isLoading,"type":"success"}},[_vm._v(" Continue ")])],1)])],1)],1):_vm._e(),_c('PpGap')],1)])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"message is-small"},[_c('div',{staticClass:"message-body"},[_c('p',{staticClass:"has-text-dark has-text-weight-semibold"},[_vm._v("You might want to:")]),_c('ul',{},[_c('li',[_vm._v("- Request a new invitation from the person in charge")])])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"form--member"},[_c('i',{staticClass:"el-icon-warning has-text-danger is-size-1 is-pulled-left"}),_c('div',{staticStyle:{"margin-left":"70px"}},[_c('h5',{staticClass:"is-size-5 has-text-weight-semibold has-text-black-ter"},[_vm._v(" Sorry! Your invitaion has expired ")]),_c('div',{staticClass:"gap"}),_c('div',{staticClass:"message is-small"},[_c('div',{staticClass:"message-body"},[_c('p',{staticClass:"has-text-dark has-text-weight-semibold"},[_vm._v("You might want to:")]),_c('ul',{},[_c('li',[_vm._v("- Request a new invitation from the person in charge")])])])])]),_c('div',{staticClass:"gap-x2"})])
},function (){var _vm=this,_c=_vm._self._c;return _c('p',{staticClass:"title is-6"},[_c('i',{staticClass:"el-icon-edit has-text-info"}),_vm._v(" Complete the form below to proceed.")])
}]

export { render, staticRenderFns }